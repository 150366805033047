import {ReactNode} from "react"
const PageLoader: ReactNode =
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '800px'
    }}>
        <span className="loader"></span>
    </div>;

export default PageLoader;
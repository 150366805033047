import axios, { AxiosResponse, AxiosRequestConfig } from "axios"
import { RequestMethod, UrlItem } from "./url-type"
import ObjectUtil from "@/utils/ObjectUtil";

export const IMAGE_URL_CONTEXT: string = `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_BACKEND_PORT}/common/image/`;

export default class HttpRequest {
    public static selectedLanguage: string = "en";
    public static userToken: string = "";

    public static async request( urlType: UrlItem, params?: any, error?: (response: AxiosResponse) => void ): Promise<AxiosResponse | undefined> {
        try {
            const config: AxiosRequestConfig = HttpRequest.getReqeustConfig( urlType, params );

            HttpRequest.showSuspend();

            const response: AxiosResponse = await axios(config);

            HttpRequest.hideSuspend();

            return response;
        } catch( err: any ) {
            HttpRequest.hideSuspend();
            
            if( err.response ) {
                if( err.response.status === 403 ) {
                    location.href = "/login";
                    return;
                }
    
                if( err.response.data.message === 403 ) {
                    alert( err.response.data.message);
                }
    
                if( err.response.status === 404 &&
                    err.response.data &&
                    err.response.data.message ) {
                    alert( err.response.data.message );
                }

                if( err.response.data && err.response.data.message ) {
                    alert( err.response.data.message );
                }
            }
        }
    }

    public static async upload( urlType: UrlItem, file: File ): Promise<AxiosResponse | void> {
        try {
            const formData: FormData = new FormData();
            formData.append("file", file );

            const config: AxiosRequestConfig = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            };

            HttpRequest.showSuspend();

            const url: string = `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_BACKEND_PORT}${urlType.url}`;
            const response: AxiosResponse = await axios.post( url, formData, config);

            HttpRequest.hideSuspend();

            return response;
        } catch( err: any ) {
            if( err && err.response ) {

                if( err.response.status === 403 ) {
                    location.href = "/login";
                    return;
                }
    
                if( err.response.data.message === 403 ) {
                    alert( err.response.data.message);
                }
            } else {
                alert("Network Problem Ocurred.\nPlease Try Again Later.");
            }
        }
    }

    private static getReqeustConfig( urlType: UrlItem, params?: any ): AxiosRequestConfig {
        let url: string = `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_BACKEND_PORT}${urlType.url}?lang=${HttpRequest.selectedLanguage}`;

        if( params ) {
            params.shopConfigId = '1';
        } else {
            params = {
                shopConfigId: '1',
            }
        }

        url = HttpRequest.transformPathVariable( url, params );

        const headers: any = HttpRequest.getHeaders();

        const result: AxiosRequestConfig = {
            url: url,
            method: urlType.method,
            headers
        };

        if( params ) {
            switch( urlType.method ) {
                case RequestMethod.GET:
                case RequestMethod.DELETE:
                    result.params = params;
                    break;
                case RequestMethod.POST:
                case RequestMethod.PUT:
                    result.data = params;
                    break;
            }
        }

        return result;
    }

    private static getHeaders(): any {
        const headers: any = {
            "Content-Type": "application/json;"
        };

        if( ObjectUtil.isNotEmpty(HttpRequest.userToken) ) {
            headers["Authorization"] = HttpRequest.userToken;
        }

        return headers;
    }
    
    private static transformPathVariable( url: string, params: any ): string {
        const variableRegex: RegExp = /(?<=\{).+?(?=\})/g;
        const variables: RegExpMatchArray | null = url.match(variableRegex);

        if( variables ) {
            variables.forEach( item => {
                const value: string = params[item];
                url = url.replace(`{${item}}`, value);

                delete params[item];
            });
        }

        return url;
    }

    private static showSuspend(): void {
        const suspend: Element | null = document.querySelector("#suspend");
        if( suspend ) {
            suspend.classList.add("active");
        }
    }

    private static hideSuspend(): void {
        const suspend: Element | null = document.querySelector("#suspend");
        if( suspend ) {
            suspend.classList.remove("active");
        }
    }
}
import produce from 'immer';
import { AuthActionType } from './actions';
import { AuthState, AUTH_ACTION } from './type';

const initialState: AuthState = {
    isLogin: false
}

const AuthReducer = ( state: AuthState = initialState, action: AuthActionType ): AuthState => {
    switch( action.type ) {
        case AUTH_ACTION.LOGIN:
            return produce(state, ( draft ) => {
                draft.isLogin = true;
            });
        case AUTH_ACTION.LOGOUT:
            return produce(state, ( draft ) => {
                draft.isLogin = false;
            });
        case AUTH_ACTION.TOKEN_VALIDATE: 
        case AUTH_ACTION.TOKEN_REFRESH:
        default:
            return state
    }
}

export default AuthReducer;
class ObjectUtil {
    /**
     * 문자열 배열에 중복 데이터를 제거하는 메서드
     * @param list 
     * @returns 
     */
    public static getDistinctArray( list: string[] ): string[] {
        let result = list.filter((element, index) => {
            return list.indexOf(element) === index;
        });
        
        return result;
    }

    /**
     * 객체가 비어있는지 확인하는 함수
     * @param obj
     */
    public static isEmpty( obj: any ): boolean {
        if( Array.isArray( obj ) ) {
            return ObjectUtil.isEmptyArray( obj );
        } else if( false === isNaN( obj ) ) {
            if( ObjectUtil.isString( obj ) ) {
                return obj === undefined || obj === null || obj.length === 0;
            }

            return obj === undefined || obj === null;
        }else {
            return obj === undefined
                    || obj === null
                    || Object.keys( obj ).length === 0;
        }
    }

    /**
     * 객체가 비어있지 않은지 확인하는 함수
     * @param obj 
     * @returns 
     */
    public static isNotEmpty( obj: any ): boolean {
        return !ObjectUtil.isEmpty( obj );
    }

    /**
     * 배열이 비어있는지 확인하는 함수
     * @param obj 
     * @returns 
     */
    public static isEmptyArray( obj: any[] ): boolean {
        return obj === undefined
            || obj === null
            || obj.length === 0;
    }

    /**
     * 배열이 비어있지 않은지 확인하는 함수
     * @param obj 
     * @returns 
     */
    public static isNotEmptyArray( obj: any[] ): boolean {
        return !this.isEmptyArray( obj );
    }

    public static isString( obj: any ){
        if(typeof obj === 'string' || obj instanceof String){
            return true;    
        }else{
            return false;
        }
    }

    public static getItemByLanguageType( list: any[], languageType: string, priority: string[] = [], targetField: string = "languageType"): any {
        let result: any = list.find( item => {
            return languageType.toUpperCase() === item[targetField].toUpperCase();
        });

        if( result ) {
            return result;
        }

        if( priority && priority.length > 0 ) {
            for( let lang of priority ) {
                result = list.find( item => {
                    return lang === item[targetField].toUpperCase();
                });
                
                if( result ) {
                    break;
                }
            }

            if( result ) {
                return result;
            }
        }

        return null;
    }

    public static serialize( basket: any[], target: any[], childrenField: string = "children"): any[] {
        target.forEach( item => {
            basket.push( item );
            
            if( item[childrenField] && item[childrenField].length > 0 ) {
                ObjectUtil.serialize( basket, item[childrenField], childrenField);
            }
        });

        return basket;
    }
}

export default ObjectUtil;
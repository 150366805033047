export const AUTH_ACTION = {
    LOGIN: "login" as const,
    LOGOUT: "logout" as const,
    TOKEN_VALIDATE: "tokenValidate" as const,
    TOKEN_REFRESH: "tokenRefresh" as const
};

export type LoginRequest = {
    id: string;
    password: string;
}

export type AuthState = {
    isLogin: boolean;
}

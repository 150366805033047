import produce from 'immer';
import { UserActionType } from './actions';
import { UserState, USER_ACTION } from './type';

const initialState: UserState = {
    user: {},
    registUser: {}
}

const UserReducer = ( state: UserState = initialState, action: UserActionType ): UserState => {
    switch( action.type ) {
        case USER_ACTION.SET_REGIST_USER:
            return produce(state, ( draft ) => {
                draft.registUser = action.payload.registUser;
            });
        default:
            return state
    }
}

export default UserReducer;
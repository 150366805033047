import produce from 'immer';
import { LanguageActionType } from './actions';
import { LanguageState, LANGUAGE_ACTION } from './type';
import HttpRequest from '@/core/http';

const initialState: LanguageState = {
    lang: 'EN',
    langLabel: "ENG"
}

const LanguageReducer = ( state: LanguageState = initialState, action: LanguageActionType ): LanguageState => {
    switch( action.type ) {
        case LANGUAGE_ACTION.CHANGE:
            return produce(state, ( draft ) => {
                draft.lang = action.payload.lang;

                switch( draft.lang ) {
                    case "KO":
                        draft.langLabel = "KOR";
                        HttpRequest.selectedLanguage = "ko";
                        break;
                    case "EN":
                        draft.langLabel = "ENG";
                        HttpRequest.selectedLanguage = "en";
                        break;
                    case "JP":
                        draft.langLabel = "JPN"
                        HttpRequest.selectedLanguage = "jp";
                }
            });
        default:
            return state
    }
}

export default LanguageReducer;
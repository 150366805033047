import { AxiosResponse } from "axios";
import HttpRequest from "./http";
import { URL_TYPE } from "./http/url-type";
import { LoginInfo, ShopConfig } from "./type";

/**
 * <p>
 * Application의 정보를 제어하고 관리하는 역할을 하는 Singleton 클래스<br />
 * 어플리케이션이 구동되고 가장 먼저 정보들을 세팅하고 값을 저장하도록 정보를 조회한다.<br />
 * 관리하는 정보의 종류는 다음과 같다.
 * </p>
 * 
 * <ul>
 *     <li>사용자 로그인 토큰</li>
 *     <li>어플리케이션 정의 정보(쇼핑몰 정보)</li>
 *     <li>사용자 언어 설정 정보</li>
 * </ul>
 */
export class Application {
    //-----------------------------------------------------------------------------
    //
    //  VARIABLES
    //
    //-----------------------------------------------------------------------------
    private static instance: Application;
    private didTokenScheduler: boolean = false;
    private refreshTimer: any = 0;
    //-----------------------------------------------------------------------------
    //
    //  PUBLIC
    //
    //-----------------------------------------------------------------------------
    public shopConfig: ShopConfig | undefined;
    public loginInfo: LoginInfo | undefined;
    //-----------------------------------------------------------------------------
    //
    //  CONSTRUCTOR
    //
    //-----------------------------------------------------------------------------
    private constructor() {

    }

    //-----------------------------------------------------------------------------
    //
    //  GET INSTANCE
    //
    //-----------------------------------------------------------------------------
    public static getInstance(): Application {
        if( !Application.instance ) {
            Application.instance = new Application();
        }

        return Application.instance;
    }

    //-----------------------------------------------------------------------------
    //
    //  METHODS : PRIVATE
    //
    //-----------------------------------------------------------------------------
    private async getShopInfo(): Promise<ShopConfig> {
        
        return new Promise<ShopConfig>(async (resolve, reject) => {
            const response: AxiosResponse | void = await HttpRequest.request( URL_TYPE.SHOP_INFO, {shopId: 1});

            if( response ) {
                resolve(response.data.data);
            } else {
                reject( );
            }
        });
    }

    private async getTokenInfo(): Promise<LoginInfo|null> {
        return new Promise<LoginInfo|null>( async (resolve, reject) => {
            const token: string | null = localStorage.getItem("token");
            
            if( token ) {
                HttpRequest.userToken = token;
                const response: AxiosResponse | void = await HttpRequest.request(URL_TYPE.TOKEN_VALIDATE, {token});

                if( response ) {
                    const result: any = response.data;

                    if( result.data.result ) {
                        const info: LoginInfo | null = await this.tokenRefresh(); // 토큰 재생성

                        if( info ) {
                            resolve( info );
                        }
                    } else {
                        localStorage.removeItem("token");
                        resolve( null );
                    }
                }
            } else {
                resolve( null );
            }
        });
    } 

    private async tokenRefresh(): Promise<LoginInfo | null> {
        if( HttpRequest.userToken ) {
            clearTimeout( this.refreshTimer );
            const response: AxiosResponse | void = await HttpRequest.request(URL_TYPE.TOKEN_REFRESH, null, () => {
                clearTimeout(this.refreshTimer);
            });
    
            if( response ) {
                const result: LoginInfo = response.data.data;
    
                if( result.token) {
                    HttpRequest.userToken = result.token;
                    localStorage.setItem("token", result.token)

                    this.didTokenScheduler = true;
    
                    this.refreshTimer = setTimeout(() => this.tokenRefresh(), parseInt(`${process.env.REACT_APP_TOKEN_REFRESH_INTERVAL}`) * 1000);
                    return result;
                }
            }
        }

        return null;
    }
 
    //-----------------------------------------------------------------------------
    //
    //  METHODS : PUBLIC
    //
    //-----------------------------------------------------------------------------
    public async initialize(): Promise<void> {
        this.shopConfig = await this.getShopInfo(); // 쇼핑몰 정보 조회/저장
    }

    public async didLogin(): Promise<boolean> {
        return new Promise<boolean>(async(resolve, reject) => {
            const loginInfo: LoginInfo | null = await this.getTokenInfo();

            if( loginInfo ) {
                resolve( true );
            } else {
                resolve( false );
            }
        });
    }
}
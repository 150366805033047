import {User} from '@/core/type';

export const USER_ACTION = {
    SET_REGIST_USER: "setRegistUser" as const
};

export type UserState = {
    user: User;
    registUser: User;
}

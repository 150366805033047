import { configureStore } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { combineReducers } from "redux";

import AuthReducer from "./auth/reducer";
import LanguageReducer from "./language/reducer";
import UserReducer from './user/reducer';

import { SelfDesignSideReducer, SelfDesignLayerReducer } from "./self-design/reducer";

const rootReducers = combineReducers({
    AuthReducer,
    LanguageReducer,
    SelfDesignLayerReducer,
    SelfDesignSideReducer,
    UserReducer
});

const AppStore: ToolkitStore = configureStore({ reducer: rootReducers });
export default AppStore;